<template>
  <v-layout
    wrap
    justify-center
    py-5
  >
    <v-flex
      xs11
      md10
      lg8
    >
      <strong
        v-font-size="18"
        class="text-uppercase"
        v-text="'Aproveite outros planos cheios de benefícios'"
      />
    </v-flex>

    <v-flex
      xs12
      my-2
    />

    <v-flex
      xs11
      md10
      lg8
    >
      <v-tabs
        height="420"
        hide-slider
        centered
        show-arrows
        class="fix--tabs-no-arrow--tvpre-cross-site"
        next-icon="mdn-Icon-direita mdn-Icon--lg"
        prev-icon="mdn-Icon-esquerda mdn-Icon--lg"
      >
        <v-tab
          v-for="tab in conteudoCardsCrossSite"
          v-show="tab.show"
          :key="`claro-controle-informacao-conteudo-${tab.name}`"
          active-class="font-weight-regular"
          class="pl-1"
        >
          <v-card
            width="260"
            height="398"
            flat
            style="border-radius: 8px;box-shadow: 0px 3px 6px #00000029;"
          >
            <v-layout
              wrap
              fill-height
            >
              <v-flex xs12>
                <v-layout
                  wrap
                  align-content-start
                >
                  <v-flex
                    xs12
                  >
                    <v-img
                      style="border-radius: 8px 8px 0 0"
                      :src="tab.image"
                      height="164"
                      width="260"
                      :alt="tab.name"
                      position="top"
                    />
                  </v-flex>

                  <v-flex
                    xs12
                    mt-3
                  />

                  <v-flex
                    xs12
                  >
                    <v-layout
                      wrap
                      justify-center
                      text-xs-left
                    >
                      <v-flex
                        xs10
                      >
                        <strong
                          v-font-size="18"
                          class="d-block py-2 text-none"
                          v-text="tab.name"
                        />
                      </v-flex>

                      <v-flex
                        xs10
                      >
                        <v-card
                          flat
                          max-height="100"
                          style="overflow-y: auto"
                        >
                          <span
                            v-font-size="16"
                            style="color:#999999"
                            class="text-none font-weight-regular"
                            v-text="tab.text"
                          />
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex
                xs12
                align-self-end
                pa-3
              >
                <v-btn
                  class="left text-none"
                  round
                  depressed
                  color="red"
                  dark
                  target="_blank"
                  rel="noopener"
                  :href="tab.url"
                  v-text="'Saiba mais'"
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab>
      </v-tabs>
    </v-flex>

    <v-flex
      xs11
      md10
      lg8
    >
      <v-layout
        py-3
        pl-1
      >
        <v-flex
          v-for="(tipo, i) in [0, 1]"
          :key="`claro-controle-informacao-${tipo}`"
          shrink
          pr-2
        >
          <v-card
            v-cursor.pointer
            v-ripple
            flat
            style="border-radius: 8px"
            color="red lighten-3"
            width="48"
            height="48"
            class="text-xs-center py-1"
            dark
          >
            <span
              :class="`mdn-Icon-${!!i ? 'direita' : 'esquerda'} mdn-Icon--lg`"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<style lang="stylus">
  .fix--tabs-no-arrow--tvpre-cross-site
    .v-tabs__bar
      background-color transparent
    .v-tabs__wrapper--show-arrows
      margin 0
    .v-tabs__div
      margin-right 14px
    .v-tabs__item
      padding: 0
      padding-right 0
      opacity 1
      cursor: default
    .v-tabs__icon--prev
      left: 4px;
    .v-tabs__icon--next
      left: 60px;
    .v-tabs__icon--prev
    .v-tabs__icon--next
      z-index 1
      background-color: red;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      color: white;
      top: 436px;
      position: absolute;

</style>

<script>
  export default {
    data: function () {
      return {
        conteudoCardsCrossSite: [
          {
            show: true,
            name: 'Claro residencial',
            text: 'Ofertas com descontos para sua casa, em combos que você monta como quiser, com planos de Internet, TV, Telefones Móvel e Fixo.',
            image: '/content/images/claromaisconteudocrosspage/claro-resiencial.png',
            url: 'https://residencial.meuplanoclaro.com.br/?utm_source=crosslanding&utm_medium=tvpre_residencial'
          },
          {
            show: true,
            name: 'Claro prezão',
            text: 'Ligações e Videos chamadas pelo Whatsapp sem descontar da internet. Chip e frete grátis na oferta mensal.',
            image: '/content/images/claromaisconteudocrosspage/claro-prezao.png',
            url: 'https://meuplanoclaro.com.br/prezao?utm_source=crosslanding&utm_medium=tvpre_pre'
          },
          {
            name: 'Claro controle',
            text: 'Faça um plano Claro Controle com Apps e Ligações ilimitadas para falar e navegar sem susto na conta!',
            image: '/content/images/claromaisconteudocrosspage/claro-controle.png',
            url: 'https://meuplanoclaro.com.br/?utm_source=crosslanding&utm_medium=tvpre_controle'
          },
          {
            name: 'Planos Claro + conteúdo',
            text: 'Nossos Planos Controle com o melhor dos seus conteúdos preferidos incluídos: Netflix, Gaming e Futebol.',
            image: '/content/images/claromaisconteudocrosspage/claro-controle-mais-conteudo.png',
            url: 'https://meuplanoclaro.com.br/?utm_source=crosslanding&utm_medium=tvpre_controle&canal=conteudo'
          },
          {
            show: true,
            name: 'Claro box tv',
            text: 'É TV, é streaming, é tudo junto, do seu jeito e onde quiser. Fácil de instalar e você escolhe os conteúdos das plataformas que preferir.',
            image: '/content/images/claromaisconteudocrosspage/claro-boxtv.png',
            url: 'https://residencial.meuplanoclaro.com.br/tv-por-assinatura/claro-box-tv?utm_source=crosslanding&utm_medium=tvpre_clarobox'
          },
          {
            show: true,
            name: 'Claro e-chip',
            text: 'Venha para a evolução do chip! Aproveite a tecnologia simples e segura, que dispensa o uso do chip físico em smartphones.',
            image: '/content/images/claromaisconteudocrosspage/claro-echip.png',
            url: 'https://meuplanoclaro.com.br/esim?utm_source=crosslanding&utm_medium=tvpre_esim'
          }
        ]
      }
    }
  }
</script>
